.main-container {
  display: flex;
  flex-direction: column;
}
.navbar-btn {
  margin-right: 20px;
}
.patners-div {
  padding-bottom: 200px;
  width: 100%;
  margin: 0px;
}
.inverted {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: transparent !important;
  border-color: white !important;
  color: white;
  width: 150px;
  background-color: transparent !important;
  border: 2px solid #bc6f42 !important;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
}
